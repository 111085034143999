// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Input.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Input.tsx");
  import.meta.hot.lastModified = "1720568675547.3489";
}
// REMIX HMR END

import { cva } from 'class-variance-authority';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { empties } from '@/constants';
import { cn } from '~/utils/cn';
import { useAdornments } from './Adornment';
export const inputVariantConfig = {
  variants: {
    variant: {
      outline: 'border bg-white',
      ghost: 'bg-gray-100'
    },
    size: {
      xs: 'h-6 text-xs file:text-xs rounded-sm px-2',
      sm: 'h-8 text-sm file:text-sm rounded-sm px-2',
      md: 'h-9 text-sm file:text-sm rounded px-3',
      lg: 'h-11 text-md file:text-md rounded-md px-3',
      xl: 'h-12 text-lg file:text-lg rounded-md px-3'
    }
  },
  defaultVariants: {
    size: 'sm',
    variant: 'outline'
  }
};
export const inputVariants = cva(`flex w-full file:font-medium ring-offset-background
    file:border-0 file:bg-transparent focus-visible:outline-none
    focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2
    disabled:cursor-not-allowed disabled:opacity-50 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none
    [&::-webkit-inner-spin-button]:appearance-none placeholder:text-neutral-400 data-[placeholder]:text-neutral-400`, inputVariantConfig);
const InputMain = _s(forwardRef(_c = _s(({
  changed,
  containerProps = empties.object,
  containerClassName,
  className,
  type,
  leftAdornment,
  rightAdornment,
  size = 'sm',
  variant = 'outline',
  autoFocus = false,
  // TODO: convert adornments to use before/after so that they're inline
  before = null,
  after = null,
  ...props
}, ref) => {
  _s();
  const {
    left,
    right,
    classNames: {
      adornedParentClass,
      adornmentPaddingClass
    }
  } = useAdornments({
    leftAdornment,
    rightAdornment,
    size
  });
  const elRef = useRef(null);
  useImperativeHandle(ref, () => elRef.current);
  useEffect(() => {
    if (autoFocus) {
      elRef.current?.focus();
    }
  }, [elRef, autoFocus]);
  return <div className={cn('relative',
  // TODO: remove changed property and add another atom for this?
  changed && 'after:absolute after:right-1.5 after:top-1.5 after:block after:h-1 after:w-1 after:rounded-full after:bg-indigo-500', adornedParentClass, containerClassName)} {...containerProps}>
        {left}
        {before}
        <input type={type} className={cn(inputVariants({
      size,
      variant,
      className
    }), adornmentPaddingClass)} ref={elRef} {...props} />
        {after}
        {right}
      </div>;
}, "R0CrnUYTA1Ke4DPWBSTpFrn7RJI=", false, function () {
  return [useAdornments];
})), "R0CrnUYTA1Ke4DPWBSTpFrn7RJI=", false, function () {
  return [useAdornments];
});
_c2 = InputMain;
InputMain.displayName = 'Input';
export const Input = InputMain;
var _c, _c2;
$RefreshReg$(_c, "InputMain$forwardRef");
$RefreshReg$(_c2, "InputMain");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;